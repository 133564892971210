import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Container, Header, Image, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { MainContents } from "../../components/MainContents";
import PageLayout from "../../components/PageLayout";
import { SiteData } from "../../consts/SiteData";
import { URIs } from "../../consts/URIs";

const Galary = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 64px;
`;
const GalaryContent = styled.div`
  text-align: center;
`;

const DDDOGPage = () => {
  const [open, setOpen] = useState(false);
  const createMarkup = ({
    src,
    iosSrc,
  }: {
    src: String;
    iosSrc: String | null;
  }) => {
    return {
      __html: `'<model-viewer
                  bounds="tight"
                  src=${src}
                  ios-src=${iosSrc}
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  camera-controls
                  environment-image="neutral"
                  poster="poster.webp"
                  shadow-intensity="1"
                  >
                  <div class="progress-bar hide" slot="progress-bar">
                      <div class="update-bar"></div>
                  </div>
                </model-viewer>'`,
    };
  };
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" prefix="og: https://ogp.me/ns#" />
        <title>{SiteData.PAGES.DDDOG.TITLE}</title>
        <meta property="og:url" content="https://www.clsl.net/products/dddog" />
        <meta property="og:type" content="og:product" />
        <meta property="og:title" content="DDDOG" />
        <meta
          property="og:description"
          content="フォトグラメトリ手法を用いたiOS用の高精度3Dスキャンアプリ DDDOG"
        />
        <meta property="og:site_name" content="Clarity Solutions" />
        <meta
          name="description"
          content="フォトグラメトリ手法を用いたiOS用の高精度3Dスキャンアプリ DDDOG"
        />
        <script
          type="module"
          src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
        ></script>
      </Helmet>
      <MainContents pageTitle="高精度3Dモデル作成アプリ「DDDOG」">
        <Container>
          <Header.Subheader style={{ padding: "30px 0", color: "red" }}>
            誠に勝手ながら、DDDOGは2023年12月31日をもってサービスを終了させていただくことになりました。
            <br />
            <br />
            これまでDDDOGをご利用いただき、誠にありがとうございました。皆様から頂戴した温かいお言葉は、常に私たちの励みとなっておりました。
            しかしながら、昨今の技術進化と市場の動向を鑑みると、本サービスを継続することが困難な状況となってまいりました。
            これまでのご愛顧に心より感謝申し上げます。私たちは今後も皆様に価値あるサービスを提供すべく努力を続けます。今後の展開にご期待いただけますと幸いです。
            引き続きのご支援とご理解を賜りますようお願い申し上げます。
            本サービスの終了後は、写真の3Dオブジェクトへの変換機能はご利用いただけなくなりますが、保存機能は継続してご利用いただけます。予めご了承ください。
          </Header.Subheader>
        </Container>

        <Header.Subheader>
          「DDDOG」はPhotogrammetry手法を用いたiOS専用の3Dモデル生成アプリです。
          3Dモデル化したい対象をiPhoneでキャプチャーすることで、
          モデル生成リクエストから最短数分でオリジナルの3DモデルがiOS端末にダウンロードされます。
          また、LiDARセンサー機能付きのデバイスを使用することで実際の大きさに近い3Dモデルを生成することができます。
        </Header.Subheader>

        <Container style={{ padding: "30px 0", textAlign: "center" }}>
          <GetDDDOGButton />
        </Container>

        <Container style={{ padding: "50px" }}>
          <Header as="h2" textAlign="center">
            DDDOGの使い方
          </Header>
        </Container>
        <div style={{ height: "35vh", width: "100%" }}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/XKVFR_ZiUZE"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <Container style={{ padding: "50px" }}>
          <Header as="h2" textAlign="center">
            DDDOGで生成した3Dモデルギャラリー
          </Header>
          <Galary>
            <GalaryContent>
              <div
                className="text"
                dangerouslySetInnerHTML={createMarkup({
                  src: "/assets/tonakai.glb",
                  iosSrc: "/assets/tonakaiUSDZ.usdz",
                })}
              />
              <Header.Subheader>DAISOで購入したトナカイの置物</Header.Subheader>
            </GalaryContent>
          </Galary>
          <Galary>
            <GalaryContent>
              <div
                className="text"
                dangerouslySetInnerHTML={createMarkup({
                  src: "/assets/ichirinzashi.glb",
                  iosSrc: "/assets/ichirinzashiUSDZ.usdz",
                })}
              />
              <Header.Subheader>陶芸家 佐藤烓 の一輪挿し</Header.Subheader>
            </GalaryContent>
          </Galary>
          <Galary>
            <GalaryContent>
              <div
                className="text"
                dangerouslySetInnerHTML={createMarkup({
                  src: "/assets/joro.glb",
                  iosSrc: "/assets/joroUSDZ.usdz",
                })}
              />
              <Header.Subheader>陶芸家 佐藤烓 のピッチャー</Header.Subheader>
            </GalaryContent>
          </Galary>
        </Container>

        <Container style={{ padding: "30px 0", textAlign: "center" }}>
          <GetDDDOGButton />
        </Container>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Modal.Content>
            <Image src={"/assets/dddog_app_screenshot.png"} fluid centered />
          </Modal.Content>
        </Modal>
      </MainContents>
    </PageLayout>
  );
};

const GetDDDOGButton = () => {
  return (
    <Button
      basic
      as="a"
      href="#"
      color="blue"
      target="_blank"
      rel="noreferrer"
      disabled={true}
    >
      <Header as="b" color="blue" textAlign="center">
        DDDOGをAppStoreで入手
      </Header>
    </Button>
  );
};

export default DDDOGPage;
